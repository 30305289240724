.tick-items{
  position: absolute;
  left: 10px;
  top: 10px;
}




@media only screen and (max-width: 850px) {
  .hide-small-screen{
    display: none;
  }
}