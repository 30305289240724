@import "app/helpers/variables.module.scss";

.bloc{
	height: 100%;
  &.open{
    width: 389px;
    z-index: 1305;
    position: relative;
    box-shadow: 0px 4px 12px 0px #3B3B3B1A;
  }
}



.arrow {
  font-size: 36px;
  font-weight: 500;
  line-height: 24px;
  text-shadow: 2px 2px 5px #979292;
}

.tick-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
}

.side-bar-mobile{
  display: none;
}

@media only screen and (max-width: 1000px) {
  .side-bar-mobile{
    cursor: pointer;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    top: 20%;
    background: $sixth-color;
    padding: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 1px 1px 4px -1px rgb(64, 65, 65);

    z-index: 1500;
    &.open{
      display: none !important;
    }
  }


  .abilities-side-bar{
    z-index: 1500 !important;
    display: none !important;
    &.open{
      display: flex !important;
      width: 100% !important;
    }
  }
}