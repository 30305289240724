@import 'app/helpers/variables.module.scss';

.course-item-container {
  box-shadow: 0px 4px 12px 0px #3B3B3B1A;
  background-color: white;
  border-radius: 10px;
  .course-name {
    font-size: 18px;
    font-weight: 900;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    color: #2DD4BF;
  }
  .small-text {
    font-size: 14px;
    font-weight: 400;
    font-family: $font-3;
  }

  .record-type{
    padding: 3px;
    margin-right: 5px;
    font-weight: 700;
    color: white;
    font-family: $font-2;
    text-transform: uppercase;
  }
}