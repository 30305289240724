.get-in-touch-container {
  border-radius: 20px !important;
  margin-bottom: 30px;
  padding: 30px;

  .card-title {
    font-family: poppins;
    font-weight: 700;
  }

  .subtitle {
    padding: 0 !important;
    font-family: mulish;
    margin-bottom: 30px;
  }

  .contact-phone {
    margin-bottom: 20px;
  }

  .link {
    text-decoration: none;
    color: #000;
    margin-left: 12px;
  }
}
