@import 'app/helpers/variables.module.scss';

$sidebar-max-width: 141px;


.sidebar-container{
	width: calc($sidebar-max-width + 8px);
}

.ability-details-container{
  box-shadow: 0px 4px 12px 0px #3B3B3B1A;
  padding: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
  background: white;
  width:100%;
  min-height:725px;
  max-height:725px;
  height: 100%;
  overflow-x: hidden;
}


.disabled-panel{
  z-index: 1200;
  top: 0;
  left: 0;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  &.skill{
    background: $third-color-faded-2;
  }
  &.attitude{
    background: $fifth-color-faded-2;
  }
  position: absolute;
}


@media only screen and (max-width: 1000px) {
  .sidebar-container{
    width: 100% !important;
  }
}