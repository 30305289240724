.hero-showcase {
  justify-content: center;
  padding: 20px;
  button {
    margin: 0 18px 0 0;
    padding: 16px 50px;
  }
  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 100%;
    letter-spacing: -0.025em;

    .design {
      color: #ff6250;
    }
  }

  .hero-left-content {

    .hero-cta-container {
      justify-content: flex-start;
    }
    h1 {
      margin-bottom: 20px;
    }

    p {
      font-family: Mulish;
      font-size: 21px;
      margin-bottom: 20px;
    }
  }


  .video-bg{
    width: 100%;
    background-color: white !important;
  }
}


@media screen and (max-width: 1200px) {
  .heading {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 800px) {
  .heading {
    font-size: 30px !important;
  }
}