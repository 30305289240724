@import 'app/helpers/variables.module.scss';

.i-c-container {
  padding: 8px 14px;
  background-color: $second-color-faded-3;
}

.s-text{
  font-size: 12px;
  font-weight: 400; 
}

.MuiPopover-paper{
  pointer-events: auto;
}

/*.paper{
  pointer-events: auto;
}*/
