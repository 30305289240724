.collaborate-container {
  background: white;
  border-radius: 10px;
  min-height: 700px;
  box-shadow: 0px 4px 30px 30px #00000008 inset, 0px 4px 4px 0px #00000040;
}

.tick-items{
  position: absolute;
  right: 20px;
  top: 20px;
}