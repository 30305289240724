@import 'app/helpers/variables.module.scss';

.knowledge-list-cover{
  box-shadow: inset 4px 4px 40px 20px #00000005, 0px 4px 4px 0px #00000040;
  padding: 20px 0;
  height: 100%;
  background: white;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;

  .big-title{
    font-size: 48px; 
    letter-spacing:0; 
    line-height: 40px
  }
  .sub-title{
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0px;
    padding: 0 3px;
  }

}

.row-hight {
  height: 45px;
}


.text-circle-tick{
  font-family: $font-4;
  font-size: 16px;
  font-weight: 500;
}
