@import 'app/helpers/variables.module.scss';


.knowledge-tick{
  height: 47px;
  border: 1px solid rgba(0, 0, 0, 0);
  &.with-error{
    animation-name: blinking-border;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

@keyframes blinking-border {
  50% {
    border: 1px solid $fourth-color;
  }
}

.right-border{
  border-right: 1px solid $second-color-faded-2;
}
