.about-us {
  padding-top: 50px;
  text-align: justify; 
  .title{
    font-size: 30px;
    font-weight: bold;
  }
  .text {
    font-size: 22px;
  }
  .logo-title{
    justify-content: center;
    display: flex;
    vertical-align: middle; 
    font-size: 80px;
  }
}