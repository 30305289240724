.title {
  font-family: Poppins !important;
  font-weight: 700 !important;
  font-size: 32px;
  text-transform: none !important;
}
.subtitle {
  font-family: Mulish !important;
  font-size: 20px !important;

}

.team-member {
  margin: 20px;

  .MuiPaper-root {
    border-radius: 20px;
    width: 213px;
    padding: 30px 20px;

    .MuiCardContent-root {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
}
.member-image {
  border-radius: 50%;
}

.some-container {
  flex-direction: column;
  justify-content: center;

  .link {
    color: #009379;
    text-decoration: none;
    /*margin: 20px;*/
  }
}
