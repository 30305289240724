@import "app/helpers/variables.module.scss";

.lc-form{
  width: 1000px;
  min-height: 300px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;

  .lc-title{
    font-size: 24px;
  }
  .lc-title{
    font-size: 24px;
  }

  .selected-item {
    display: inline-block;
    padding: 5px; 
    margin: 0px 5px;
    margin-bottom: 5px; 
  }

  .record-type{
    padding: 3px;
    margin-right: 5px;
    font-weight: 700;
    color: white;
    font-family: $font-2;
    text-transform: uppercase;
  }
}