@import "app/helpers/variables.module.scss";

.sel-ab-container{
  box-sizing: border-box;
  height: 100%;
  padding: 20px 5px;
  border-radius: 30px;

  .edit-icon{
    display: none;
  }

  .selected-ability{
    padding: 5px 15px;
    display: flex;
    cursor: pointer;
    &:hover, &.hover{
      box-shadow: 0px 4px 4px 0px #00000040;
    }
  }

  .selected-item{
    border-radius: 10px;
    padding: 5px 15px;
    cursor:pointer;
    &:hover, &.hover{
      box-shadow: 0px 4px 4px 0px #00000040;
      .edit-icon{
        display: inline;
      }
    }

  }
  &.skill{
    border: 1px solid $third-color;

    .selected-item:hover, .selected-item.hover, .selected-ability.hover, .selected-ability:hover{
      background: $third-color-faded-2;
    }

  }
  &.attitude{
    border: 1px solid $fifth-color;

    .selected-item:hover, .selected-item.hover, .selected-ability.hover, .selected-ability:hover{
      background: $fifth-color-faded-2;
    }
  }


}