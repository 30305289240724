@import 'app/helpers/variables.module.scss';

$gap: 60px;


@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

.ability-bloc{
  width: 324px;
  position:relative;

  .uncompleted-indicator{
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .bloc-shades {
    box-shadow: 1px 1px 3px -2px rgba(64, 65, 65, 8.25098);
    position:absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    padding: 25px;
    box-sizing: border-box;
    .number{
      font-family: $font-1;
      font-size: 96px;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: 0px;
      color:white;


    }
    .text{

      font-family: $font-2;
      font-size: 32px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0px;
      text-align: left;
      color:white;
    }

    .action{
      font-size: 20px;
      font-style: italic;
      font-weight: 400;
      color: white;
      font-family: $font-2;
    }

  }
  .shade-1{
    box-shadow: 10px 4px 12px 0px #3B3B3B40;
    background: $main-color;
  }


  $sizes: 12;
  $modified-col: tint($main-color,1%);
  @mixin shade-classes {
    @for $i from 1 through $sizes {
      $modified-col: tint($modified-col,15%);
      .shade-#{$i+1} {

        background: tint($modified-col, 5%);
        left:calc(($gap*$i ) );
      }
    }
  }

  @include shade-classes;

}
