@import 'app/helpers/variables.module.scss';


.elm-tick{
  height: 39px;
}

.one-line {
  width: 100%;
  display: flex; /* important */
  white-space: nowrap;
  overflow: hidden;

  /* when not hovering show ellipsis */
  &:not(:hover) {
    text-overflow: ellipsis;
  }

  /* animate on either hover or focus */
  &:hover,
  &:focus {
    span {
      display: flex;
      animation-name: scroll-text;
      animation-duration: 4s;
      animation-timing-function: linear;
      animation-delay: 0;
      animation-iteration-count: infinite;
      animation-direction: normal;
      /* FYI this would be the shorthand:
        animation: scroll-text 5s ease 0s 2 normal;
      */
    }
  }

  /* define the animation */
  @keyframes scroll-text {
    0% {
      transform: translateX(0%);
    }
    79.99% {
      transform: translateX(-60%);
    }
    80% {
      transform: translateX(0%);
    }
    
    100% {
      transform: translateX(0%);
    }
  }
}


.hiddenBisTxt{
  font-size: 14px;
  position: fixed;
  width: auto;
  left: -50%;
  top: -50%;
}