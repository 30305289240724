@import 'app/helpers/variables.module.scss';

.signin-container {
  background: white;
  border-radius: 24px;
  border: 1px solid $second-color-faded-3;
}

.MuiDivider-root::before, .MuiDivider-root::after{
  border-top: 3px solid black !important;
}

.ag-chart-wrapper canvas {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

