@import 'app/helpers/variables.module.scss';

.courses-container{
  background: white;
  border-radius: 25px;
  .username {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0em;
  }
}


.MuiTabs-scroller{
  .MuiTabs-indicator{
    background: $fourth-color-faded;
    height: 3px;
  }
  .MuiTab-root.Mui-selected{
    color: $fourth-color-faded;
    font-family: $font-3;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: capitalize;
  }
}