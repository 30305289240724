@import "app/helpers/variables.module.scss";



.feature-container {
  margin-top: 50px;

  .feature-card {
    border-radius: 20px;
    background: #fff;
    box-shadow: 34.854148864746094px 29.626028060913086px 48.34000015258789px
      0px rgba(51, 102, 255, 0.05);
    display: flex;
    width: 370px;
    padding: 40px 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 480px;
  }

  .title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    text-transform: none;
  }

  .sub-title {
    line-height: 30px;
    font-family: Mulish !important;
    font-size: 20px;
  }

  .feature-title {
    color: black;
    text-align: center;
    font-family: $font-3;
    font-weight: bold;
    font-size: 20px;
    font-style: normal;
    line-height: 130%;
    letter-spacing: +1.5px;
    margin: 00px 0 24px;
  }

  u{
    color: $third-color;
    text-decoration: none;
    font-weight: bold;
  }

  .feature-content {
    color: #2d2d2d;
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .feature-svg {
    width: 100px;
  }
}




@media screen and (min-width: 901px) {
  .disp-desktop{
    display: flex ;
  }

  .disp-mobile{
    display: none ;
  }
}

@media screen and (max-width: 900px) {
  .disp-desktop{
    display: none !important;
  }

  .disp-mobile{
    display: none ;
  }

  .img-1{
    height: 400px;
  }
}
