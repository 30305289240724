/* You can add global styles to this file, and also import other style files */
@import "app/helpers/variables.module.scss";

html,
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  height: 100%;
  background: #f4f5f0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.d-f {
  display: flex !important;
}

.fd-r {
  flex-direction: row !important;
}

.fd-c {
  flex-direction: column !important;
}

.jc-sa {
  justify-content: space-around !important;
}

.jc-sb {
  justify-content: space-between !important;
}

.jc-c {
  justify-content: center !important;
}

.jc-r {
  justify-content: right !important;
}

.ai-c {
  align-items: center !important;
}

.ai-t {
  align-items: flex-start !important;
}

.fx-1 {
  flex: 1;
}

.d-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

$sizes: 12;
@mixin padding-classes {
  @for $i from 0 through $sizes {
    $padding: $i * 8px;
    .p-#{$i} {
      padding: $padding !important;
    }
    .pl-#{$i} {
      padding-left: $padding;
    }
    .pr-#{$i} {
      padding-right: $padding;
    }
    .pt-#{$i} {
      padding-top: $padding;
    }
    .pb-#{$i} {
      padding-bottom: $padding;
    }
    .px-#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .py-#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}
@include padding-classes;

.mt-a {
  margin-top: auto !important;
}
.mb-a {
  margin-bottom: auto !important;
}
.ml-a {
  margin-left: auto !important;
}
.mr-a {
  margin-right: auto !important;
}
.mx-a {
  margin-left: auto !important;
  margin-right: auto !important;
}

$sizes: 12;
@mixin margin-classes {
  @for $i from 0 through $sizes {
    $margin: $i * 8px;
    .m-#{$i} {
      margin: $margin;
    }
    .ml-#{$i} {
      margin-left: $margin;
    }
    .mr-#{$i} {
      margin-right: $margin;
    }
    .mt-#{$i} {
      margin-top: $margin !important;
    }
    .mb-#{$i} {
      margin-bottom: $margin;
    }
    .mx-#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my-#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}
@include margin-classes;

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

//--------------------------------------

//colors

.main-color {
  color: $main-color;
}

.main-color-faded {
  color: $main-color-faded;
}

.main-color-dark {
  color: $main-color-dark;
}

.second-color {
  color: $second-color;
}

.second-color-faded {
  color: $second-color-faded;
}

.second-color-faded-2 {
  color: $second-color-faded-2;
}

.second-color-faded-3 {
  color: $second-color-faded-3;
}

.third-color {
  color: $third-color;
}

.third-color-faded {
  color: $third-color-faded;
}

.third-color-faded-2 {
  color: $third-color-faded-2;
}

.fourth-color {
  color: $fourth-color;
}

.fifth-color {
  color: $fifth-color;
}

.error-color {
  color: $fourth-color;
}

.white-color {
  color: #fff;
}

//bg-colors

.bg-white-color {
  background: #fff;
}

.bg-main-color {
  background: $main-color;
}

.bg-main-color-faded {
  background: $main-color-faded;
}

.bg-main-color-faded-2 {
  background: $main-color-faded-2;
}

.bg-second-color {
  background: $second-color;
}

.bg-second-color-faded {
  background: $second-color-faded;
}

.bg-second-color-faded-2 {
  background: $second-color-faded-2;
}

.bg-second-color-faded-3 {
  background: $second-color-faded-3;
}

.bg-third-color {
  background: $third-color;
}

.bg-third-color-faded {
  background: $third-color-faded;
}

.bg-third-color-faded-2 {
  background: $third-color-faded-2;
}

.bg-fourth-color {
  background: $fourth-color;
}

.center-text {
  text-align: center;
}

.center-el {
  margin: 0 auto;
}

//vertical spaces
$sizes: 10;
@mixin v-space-classes {
  @for $i from 1 through $sizes + 1 {
    $height: $i * 4px;
    .v-space-#{$i} {
      width: 100%;
      height: $height;
    }
  }
}

.h-space-1 {
  width: 5px;
  height: 100%;
}

@include v-space-classes;

//fonts

.font-1 {
  font-family: $font-1, sans-serif;
}

.font-2 {
  font-family: $font-2, sans-serif;
}

.font-3 {
  font-family: $font-3, sans-serif;
}


//others

.p-r {
  position: relative;
}

.pointer {
  cursor: pointer;
}


.hide-bloc{
  position: fixed;
  background: white;
  z-index: 1500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bs-bx {
  box-sizing: border-box;
}

.cap {
  text-transform: capitalize;
}

.upper {
  text-transform: uppercase;
}

.turncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropbox{
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  background: #ffffff;
  border-color: #edecec;
  outline: none !important;
  box-shadow: 0px 4px 12px 0px #3b3b3b1a;
  border-style: solid;
  height: 50px;
  width: 100%;
}

.field {
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  background: #ffffff;
  border-color: #edecec;
  outline: none !important;
  box-shadow: 0px 4px 12px 0px #3b3b3b1a;
  border-style: solid;
  height: 50px;
  &.textarea {
    height: 208px;
  }
  &:focus {
    border-color: #ccc;
    box-shadow: 0 0 10px #ccc;
  }
}


//links
a {
  text-decoration: none;
}

.underline{
  text-decoration: underline;
}

//Checkboxs

.MuiCheckbox-root {

  &.ch-faded {
    color: $second-color-faded-3;
    &.Mui-checked {
      color: $second-color-faded-3;
    }
  }

  &.ch-main {
    &.Mui-checked {
      color: $main-color;
    }
  }

  &.ch-third {
    color: $third-color;
    &.Mui-checked {
      color: $third-color;
    }
  }

  &.ch-fifth {
    color: $fifth-color;
    &.Mui-checked {
      color: $fifth-color;
    }
  }

  /*-------------------------*/

  &.ch-aa {
    color: $fourth-color-faded-3;
    &.Mui-checked {
      color: $fourth-color-faded-3;
    }
  }

  &.ch-ai {
    color: $fifth-color-i;
    &.Mui-checked {
      color: $fifth-color-i;
    }
  }

  &.ch-at {
    color: $fifth-color-t;
    &.Mui-checked {
      color: $fifth-color-t;
    }
  }

  &.ch-ae {
    color: $fifth-color-e;
    &.Mui-checked {
      color: $fifth-color-e;
    }
  }

  /*---------------------*/

  &.ch-sa {
    color: $fourth-color-faded-3;
    &.Mui-checked {
      color: $fourth-color-faded-3;
    }
  }

  &.ch-si {
    color: $third-color-i;
    &.Mui-checked {
      color: $third-color-i;
    }
  }

  &.ch-st {
    color: $third-color-t;
    &.Mui-checked {
      color: $third-color-t;
    }
  }

  &.ch-se {
    color: $third-color-e;
    &.Mui-checked {
      color: $third-color-e;
    }
  }
}


.bold{
  font-weight: bold;
}