@import "app/helpers/variables.module.scss";

.logo {
  cursor: pointer;
  margin-left: 20px;
  img{
     width:120px;
  }
}

.profile-picture{
  height: 56px;
  width: 56px;
  border-radius: 100%;
}

.menu-mobile{
  display: none;
}


.close-btn{
  display: none;
}

@media screen and (max-width: 500px) {
  .close-btn{
    display: flex ;
  }

  .logo img{
    margin-top: 12px;
    width: 70px !important;
  }

  .menu-mobile{
    display: flex !important;
  }
  .menu {
    display: none !important;
    width: 100% !important;
  }

  .menu-display {
    z-index: 1500;
    display: flex !important;
    width: 100% !important;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column !important;
    background-color: white;
    border-bottom: 2px solid $main-color;
  }
}

