.reserve {
  justify-content: space-between;
  padding: 0 50px;
  min-height: 80px;
  background-color: #000;
  color: #fff;
  margin-top: auto;

  .fb-icon {
    color: #fff;
  }
}
