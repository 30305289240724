@import "app/helpers/variables.module.scss";

.sel-cl-container{
  box-sizing: border-box;
  height: 800px;
  padding: 20px 5px;
  border-radius: 10px;
  border: 1px solid $main-color-faded-2;
  background: white;
  box-shadow: 0px 4px 30px 30px #00000008 inset, 0px 4px 4px 0px #00000040;

  .selected-item{
    border-radius: 10px;
    padding: 5px 15px;
    /*color: $main-color;*/
    cursor:pointer;
/*    &:hover, &.hover{
      box-shadow: 0px 4px 4px 0px #00000040;
      .edit-icon{
        display: inline;
      }
    }*/

  }

}