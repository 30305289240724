@import 'app/helpers/variables.module.scss';

.title{
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 400; 	
}

.cover{
  background: #FFFFFF;

  box-shadow: inset 0px 40px 30px 0px rgba(0, 0, 0, 0.02), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) ;

  height: 300px;
}

.h-95{
  height: 95%;
}

.custom-hight {
  height: 100px;
}

.text{
  font-weight: 400;   
	font-size: 12px;
  word-wrap: break-word;
}

.edit{
  background: $main-color-faded-2;
  padding: 16px;
  color: $main-color;
  border-radius: 16px;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05);
  cursor:pointer;
}

