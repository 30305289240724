@import 'app/helpers/variables.module.scss';

.item{
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  color: $second-color;
  padding-top: 8px;  
  padding-bottom: 10px;
  .active {
    font-weight: 700;
    color: $fourth-color-faded;
  }  
}