
//colors

$main-color: #6B3493;
$main-color-opac: rgba($main-color, .22);


$main-color-faded: #EADDFF;
$main-color-faded-2: #FEF7FF;
$main-color-faded-3: #9673AF;
$main-color-dark: #21005D;


$second-color: #606060;

$second-color-faded: #fafafa;
$second-color-faded-2: #e5e5e5;
$second-color-faded-3: #B0B0B0;
$second-color-faded-4: #CFCFCF;


$third-color: #009379;
$third-color-faded: #35C37D;
$third-color-faded-2: #E5F4F2;
$third-color-faded-3: #32a28e;
$third-color-faded-4: #56b4a3;

$third-color-e: #c6e3c3;
$third-color-i: #8fcc90;
$third-color-t: #56b4a3;


$fourth-color: #DA4452;
$fourth-color-faded: #FF6250;
$fourth-color-faded-2: #ff7961;
$fourth-color-faded-3: #ed017f;


$fifth-color: #4A8ADD;
$fifth-color-faded: #77b2ff;
$fifth-color-faded-2: #d2e2f7;
$fifth-color-faded-3: #629eec;
$fifth-color-faded-4: #8cb4e9;

$fifth-color-e: #a2d8e5;
$fifth-color-i: #51c3c7;
$fifth-color-t: #438ca9;


$sixth-color: #F8D57E;
$sixth-color-faded: #EB7001;




//fonts

$font-1: 'Bebas Neue';
$font-2: 'Poppins';
$font-3: 'Mulish';
$font-4: 'Roboto';


:export{
  main-color: $main-color;
  main-color-opac: $main-color-opac;
  main-color-faded: $main-color-faded;
  main-color-faded-2: $main-color-faded-2;
  main-color-faded-3: $main-color-faded-3;
  main-color-dark: $main-color-dark;

  second-color: $second-color;
  second-color-faded: $second-color-faded;
  second-color-faded-2: $second-color-faded-2;
  second-color-faded-3: $second-color-faded-3;
  second-color-faded-4: $second-color-faded-4;


  third-color: $third-color;
  third-color-faded: $third-color-faded;
  third-color-faded-2: $third-color-faded-2;  
  third-color-faded-3: $third-color-faded-3;
  third-color-faded-4: $third-color-faded-4;



  fourth-color: $fourth-color;
  fourth-color-faded: $fourth-color-faded;
  fourth-color-faded-2: $fourth-color-faded-2;
  fourth-color-faded-3: $fourth-color-faded-3;


  fifth-color: $fifth-color;
  fifth-color-faded: $fifth-color-faded;
  fifth-color-faded-2: $fifth-color-faded-2;
  fifth-color-faded-3: $fifth-color-faded-3;
  fifth-color-faded-4: $fifth-color-faded-4;


  sixth-color: $sixth-color;
  sixth-color-faded: $sixth-color-faded;



  third-color-e: $third-color-e;
  third-color-i: $third-color-i;
  third-color-t: $third-color-t;


  fifth-color-e: $fifth-color-e;
  fifth-color-i: $fifth-color-i;
  fifth-color-t: $fifth-color-t;

  font-1: $font-1;
  font-2: $font-2;
  font-3: $font-3;
  font-4: $font-4;

}
