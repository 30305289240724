@import 'app/helpers/variables.module.scss';

.spec-height{
  height: 100px;
}

.close-panel{
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 1500;
}

.one-panel{
  height: 100%;
  width: 25%;
}

.mobile-shade-panel{
  cursor: pointer;
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: inset 0px 4px 30px 30px rgba(0, 0, 0, 0.03), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .h-delimiter{
    box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 1px;
    background: lightgray;
  }
  
  &.skill{
    .text{
      margin-bottom: 30px;
      transform: rotate(-90deg);
    }
    background: $third-color-faded-2;
  }
  &.attitude{
    .text{
      margin-bottom: 50px;
      transform: rotate(90deg);
    }
    background: $fifth-color-faded-2;
  }
}

@media only screen and (max-width: 1350px) {
  .disabled-fstep {
    display: none !important;
  }

  .active-fstep {
    width: 45% !important;
  }
  .mobile-shade-panel{
    width: 10%;
    display: flex;
  }
}

