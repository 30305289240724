@import 'app/helpers/variables.module.scss';

.elm-list-cover{
  position: relative;
  height: 100%;
  padding: 20px 0;
  background: white;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: inset 0px 4px 30px 30px rgba(0, 0, 0, 0.03), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .big-title{
    font-size: 48px; 
    letter-spacing:0; 
    line-height: 40px
  }
  .sub-title{
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0px;
    padding: 0 3px;
  }

}

.scroll-component-exp{
  /*height: 100%;*/
  height: 440px;
  width: 100%;
}

.ab-title-hight {
  height: 35px;
}

.row-hight {
  height: 45px;
}