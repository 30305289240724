.app-circle{
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  &.margined-1{
    margin: 1px;
  }
}